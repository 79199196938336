// # 
// # Cube Effect
// ##############

#{$effect-class}__cube #{$base-class}
  &--before
    transform: translateX(-332px) rotateY(-70deg) translateX(-652px) rotateY(-110deg) translateX(-530px)
  &--before-1
    transform: translateX(-332px) rotateY(-70deg) translateX(-512px)
    opacity: 0.5
  &--after
    transform: translateX(332px) rotateY(70deg) translateX(652px) rotateY(110deg) translateX(530px)
  &--after-1
    transform: translateX(332px) rotateY(70deg) translateX(512px)
    opacity: 0.5
