// # 
// # Classic Effect
// ##############

#{$effect-class}__classic #{$base-class}
  &--before
    transform: translate3d(-1920px, 0, 0)
  &--before-2
    transform: translate3d(-1280px, 0, 0)
    opacity: 0.1
  &--before-1
    transform: translate3d(-640px, 0, 0)
    opacity: 0.3
  &--after
    transform: translate3d(1920px, 0, 0)
  &--after-2
    transform: translate3d(1280px, 0, 0)
    opacity: 0.1
  &--after-1
    transform: translate3d(640px, 0, 0)
    opacity: 0.3
