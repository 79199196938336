// # 
// # Arc Effect
// ##############

#{$effect-class}__arc #{$base-class}
  &--before
    transform: translateX(-750px) translateY(240px) rotateZ(-17deg) scale(0.5)
    opacity: 0
  &--before-2
    transform: translateX(-560px) translateY(160px) rotateZ(-14deg) scale(0.6)
    opacity: 0.3
  &--before-1
    transform: translateX(-350px) translateY(75px) rotateZ(-8deg) scale(0.8)
    opacity: 0.5
  &--after
    transform: translateX(750px) translateY(240px) rotateZ(17deg) scale(0.5)
    opacity: 0
  &--after-1
    transform: translateX(350px) translateY(75px) rotateZ(8deg) scale(0.8)
    opacity: 0.5
  &--after-2
    transform: translateX(560px) translateY(160px) rotateZ(14deg) scale(0.6)
    opacity: 0.3
