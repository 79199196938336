// # 
// # Sine Wave Effect
// ######################

#{$effect-class}__wave #{$base-class}
  &--before
    transform: translateX(-690px) translateY(120px) rotateZ(-6deg) rotateY(-15deg) scale(0.4)
    opacity: 0
  &--before-2
    transform: translateX(-560px) translateY(100px) rotateZ(-14deg) rotateY(-40deg) scale(0.55)
    opacity: 0.3
  &--before-1
    transform: translateX(-410px) translateY(55px) rotateZ(-11deg) rotateY(-30deg) scale(0.8)
    opacity: 0.5
  &--after
    transform: translateX(690px) translateY(120px) rotateZ(6deg) rotateY(15deg) scale(0.4)
    opacity: 0
  &--after-1
    transform: translateX(410px) translateY(55px) rotateZ(11deg) rotateY(30deg) scale(0.8)
    opacity: 0.5
  &--after-2
    transform: translateX(560px) translateY(100px) rotateZ(14deg) rotateY(40deg) scale(0.55)
    opacity: 0.3
