// #
// # Bee3D Base Styles
// ##########

*
	&,
	&:before,
	&:after
		+box-sizing(border-box)

body
	overflow-x: hidden

#{$parent-class}
  +perspective(600px)

#{$slide-class}
  position: absolute
  transform: none
  width: $slide-w
  height: $slide-h
  top: 50%
  left: 50%
  // slide spacing
  margin-left: -$slide-w/2
  margin-top: -$slide-h/2
  // all slides start invisible
  // each effect dictates which are seen
  opacity: 0
  +transition(opacity .7s ease, transform .7s ease)

  &__inactive
    +user-select(none)
    pointer-events: none

  &__active
    opacity: 1
    z-index: 1

#{$inner-class}
  position: relative
  width: 100%
  height: 100%
