// # 
// # Coverflow Effect
// ##############

#{$effect-class}__coverflow #{$base-class}
  &--before
    transform: translateX(-270px) rotateY(45deg) scale(0.6)
    z-index: 6
  &--before-3
    transform: translateX(-230px) rotateY(45deg) scale(0.6)
    opacity: 0.1
    z-index: 7
  &--before-2
    transform: translateX(-190px) rotateY(45deg) scale(0.6)
    opacity: 0.2
    z-index: 8
  &--before-1
    transform: translateX(-150px) rotateY(45deg) scale(0.6)
    opacity: 0.3
    z-index: 9
  &__active
    z-index: 10
  &--after
    transform: translateX(270px) rotateY(-45deg) scale(0.6)
    z-index: 6
  &--after-1
    transform: translateX(150px) rotateY(-45deg) scale(0.6)
    opacity: 0.3
    z-index: 9
  &--after-2
    transform: translateX(190px) rotateY(-45deg) scale(0.6)
    opacity: 0.2
    z-index: 8
  &--after-3
    transform: translateX(230px) rotateY(-45deg) scale(0.6)
    opacity: 0.1
    z-index: 7
