// # 
// # Carousel Effect
// ##############

#{$effect-class}__carousel #{$base-class}
  &--before
    transform: translateX(-980px) rotateY(-72deg) translateX(-1290px)
    opacity: 0
  &--before-2
    transform: translateX(-590px) rotateY(-52deg) translateX(-780px)
    opacity: 0.3
  &--before-1
    transform: translateX(-270px) rotateY(-30deg) translateX(-290px)
    opacity: 0.5
  &--after
    transform: translateX(980px) rotateY(72deg) translateX(1290px)
    opacity: 0
  &--after-1
    transform: translateX(270px) rotateY(30deg) translateX(290px)
    opacity: 0.5
  &--after-2
    transform: translateX(590px) rotateY(52deg) translateX(780px)
    opacity: 0.3
