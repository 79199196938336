// # 
// # Mouse drag/swipe
// ###################

.draggable
  cursor: move // fallback if grab cursor is unsupported 
  cursor: grab
  cursor: -moz-grab
  cursor: -webkit-grab
  &:active
    cursor: grabbing
    cursor: -moz-grabbing
    cursor: -webkit-grabbing
