// # 
// # Concave Effect
// ##############

#{$effect-class}__concave #{$base-class}
  &--before
    transform: translateX(-800px) rotateY(90deg) translateX(-800px)
  &--before-1
    transform: translateX(-310px) rotateY(28deg) translateX(-240px)
    opacity: 0.4
  &--after
    transform: translateX(800px) rotateY(-90deg) translateX(800px)
  &--after-1
    transform: translateX(310px) rotateY(-28deg) translateX(240px)
    opacity: 0.4
