// #
// # Bee3D Styles Configuration
// 

/*
 * Class Names
 */
$base-class 				: '.bee3D'
$inner-class 				: '.bee3D--inner'
$effect-class 			: '.bee3D--effect'
$wrapper-class 			: '.bee3D--wrapper'
$parent-class 			: '.bee3D--parent'
$slide-class 				: '.bee3D--slide'
$nav-class 					: '.bee3D--nav'
$shadow-wrap-class 	: '.bee3D--shadow-wrapper'
$shadow-class 			: '.bee3D--shadow'
$parallax-class 		: '.bee3D--parallax'

$slide-w 				: 512px
$slide-h 				: 380px
