// # 
// # Shadows
// ##############

#{$shadow-wrap-class}
  position: absolute
  z-index: -1
  left: 0
  width: 100%
  height: 100px

#{$shadow-class}
  > span
    display: block
    content: ''
    left: 0
    width: 100%
    height: 200px
    border-radius: 50%
    background: rgba(0,0,0,.45)
    box-shadow: 0 0 200px 50px rgba(0,0,0,.7)
    transition: opacity 1.5s
    opacity: .15
    transform: rotateX(95deg) translateZ(30px) scale(0.55)
